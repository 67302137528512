<template>
  <b-col md="6" lg="4" class="mb-3">
    <div v-if="reportData.hasValues">
      <b-card footer-class="p-0 overflow-hidden">
        <template #header>
          <card-header
            :title="reportData.value"
          />
        </template>
        <div class="text-center">
          <h1 :class="totalHighlightClasses">
            {{ reportData.dataValues.countTotal }}
          </h1>
        </div>
        <template #footer>
          <div class="chart__progress-bars" v-if="hasData">
            <div :style="positiveColWidth">
              <b-progress
                :value="reportData.dataValues.countPositive"
                :max="reportData.dataValues.countPositive"
                show-value
                variant="success"
                class="progress--is-squared"
              />
            </div>
            <div :style="negativeColWidth">
              <b-progress
                :value="reportData.dataValues.countNegative"
                :max="reportData.dataValues.countNegative"
                show-value
                variant="danger"
                class="progress--is-squared"
              />
            </div>
          </div>
          <div class="chart-unavailable__count" v-if="!hasData">
            <span>N/A</span>
          </div>
        </template>
      </b-card>
    </div>
    <div v-else class="d-flex h-100 flex-column">
      <b-card footer-class="p-0 overflow-hidden">
        <template #header>
          <card-header
            :title="reportData.value"
          />
        </template>
        <div class="text-center">
          <h1 :class="totalHighlightClasses">
            0
          </h1>
        </div>
        <template #footer>
          <div class="chart-unavailable__count">
            <span>N/A</span>
          </div>
        </template>
      </b-card>
    </div>
  </b-col>
</template>

<script>
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'ReportDataSection',
  components: { CardHeader },
  props: {
    reportData: {
      key: String,
      value: String,
      hasValues: Boolean,
      dataValues: {
        countTotal: Number,
        countPositive: Number,
        countNegative: Number,
        sectionPoint: Number,
        averagePoint: Number,
      },
    },
  },
  computed: {
    hasData() {
      return !(this.reportData.dataValues.countPositive <= 0 && this.reportData.dataValues.countNegative <= 0);
    },
    positiveColWidth() {
      console.log('reportData.value', this.reportData.value, this.reportData.hasValues);
      if (this.reportData.hasValues) {
        return `width: ${(this.reportData.dataValues.countPositive / this.reportData.dataValues.countTotal) * 100}%`;
      }
      return '';
    },
    negativeColWidth() {
      if (this.reportData.hasValues) {
        return `width: ${(this.reportData.dataValues.countNegative / this.reportData.dataValues.countTotal) * 100}%`;
      }
      return '';
    },
    totalHighlightClasses() {
      if (this.reportData.hasValues) {
        if (this.reportData.dataValues.countTotal > 99 && this.reportData.dataValues.countTotal < 999) {
          return 'm-auto point-highlight point-highlight--100';
        }
        if (this.reportData.dataValues.countTotal > 999) {
          return 'm-auto point-highlight point-highlight--1000';
        }
      }
      return 'm-auto point-highlight';
    },
  },
};
</script>

<style scoped>

</style>
